/* ======== Card langkah ========*/
.card {
  max-width: 344px;
  padding: 30px 25px;
  border: none;
  border-radius: 20px;
  background-color: var(--bg);
}
.icon {
  background-color: var(--main);
  width: fit-content;
  padding: 19px;
  border-radius: 50%;
}
.title h4 {
  margin: 20px 0px;
  color: var(--header);
  font-size: 18px;
  font-weight: 600;
}
.desc p {
  color: var(--text);
  font-size: 14px;
  margin-bottom: 0 !important;
  max-height: 42px;
  overflow: hidden;
}

/* ======== Card perusahaan ========*/
.perusahaan {
  max-width: 344px;
  height: auto;
  display: flex;
  flex-direction: column;
  background-color: var(--justWhite);
  border-radius: 20px;
}
.perusahaan .info {
  padding: 31px 40px 31px 35px;
}
#logo-perusahaan {
  background-color: var(--bg);
  width: 70px;
  height: 70px;
  border-radius: 8px;
  margin-bottom: 17px;
}
.info .perusahaan-text {
  margin-bottom: 24px;
}
.info .perusahaan-text h3 {
  margin-bottom: 0px;
}
.info h3 {
  color: var(--header);
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 17px;
}
.info .perusahaan-title {
  margin: auto;
  margin-left: 15px;
}
.info .perusahaan-title h3 {
  color: var(--header);
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 0;
}
.info .perusahaan-title p {
  color: var(--text);
  font-size: 11px;
  font-weight: 500;
  margin-bottom: 0;
}
.info .deskripsi {
  max-height: 45px;
  overflow: hidden;
}
.info p {
  color: var(--text);
  font-size: 13px;
  line-height: initial;
  margin-bottom: 0 !important;
}
.perusahaan .location {
  border-top: 2px solid var(--bg);
  padding: 20px 28px;
}
.perusahaan .location h4 {
  color: var(--main);
  font-size: 14px;
  margin-bottom: 0;
}

/* card lowongan */
.no-under {
  text-decoration: none !important;
}
.lowongan .info {
  padding-bottom: 62px;
}
.lowongan #logo-perusahaan {
  margin-bottom: 0px;
  width: 50px;
  height: 50px;
}
.lowongan .flex {
  margin-bottom: 17px;
}
.lowongan .info .flex {
  display: flex;
}

/* card berita */
.card-berita {
  max-width: 344px;
  height: auto;
  border-radius: 20px;
  background-color: var(--justWhite);
}
.card-berita img {
  width: 100%;
  height: 221px;
  border-radius: 20px 20px 0 0;
  object-fit: cover;
}
.card-berita .berita-text {
  padding: 40px 30px;
}
main .berita-text span {
  color: var(--text);
  font-size: 14px;
  font-weight: 400;
}
main .berita-text h3 {
  margin-top: 13px;
  margin-bottom: 17px;
  font-weight: 600;
  font-size: 16px;
  color: var(--header);
}
main .berita-text a {
  font-size: 14px;
  font-weight: 500;
  color: var(--main);
  text-decoration: none;
  transition: 0.5s;
}
main .berita-text a:hover {
  text-decoration: underline !important;
}
.contain-co {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 27px;
}

/* responsive */
@media screen and (min-width: 768px) {
  .hero-co-text {
    min-width: 250px;
  }
  .contain-co {
    gap: 40px;
    row-gap: 35px;
  }
  .card {
    max-width: 300px;
  }
  .card-berita {
    max-width: 300px;
  }
  .perusahaan {
    max-width: 300px;
  }
}
@media screen and (min-width: 1020px) {
  .card {
    max-width: 344px;
  }
  .card-berita {
    max-width: 344px;
  }
  .perusahaan {
    max-width: 344px;
  }
  /* langkah */
  .title h4 {
    margin: 11px 0px;
    font-size: 22px;
  }
}
