footer {
  background-image: url("/public/img/qasuary/footer.svg");
  background-size: cover;
  background-repeat: no-repeat;
}
footer .flex {
  display: flex;
  justify-content: space-around;
  padding: 104px 84px;
}
footer .foot-1 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 222px;
  max-width: 344px;
}
footer img {
  width: 152px;
}
footer .foot-1 h3,
footer .foot-1 h4,
footer .foot-2 a,
footer .foot-2 a:hover,
footer .foot-3 a,
footer .foot-3 h4 {
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  margin: 0;
  color: var(--justWhite);
}
footer .foot-2 {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}
footer .foot-2 a {
  width: 40%;
  flex: calc(100% / 4);
  text-decoration: none !important;
}
footer .foot-2 a:last-child {
  width: 0;
}
footer .foot-3 {
  width: 248px;
}
footer .foot-3 h3 {
  font-weight: 600;
  font-size: 14px;
  line-height: 25px;
  color: var(--justWhite);
}
footer .foot-3 a {
  text-decoration: underline !important;
}
@media screen and (min-width: 1025px) {
  footer .foot-2 {
    width: 343px;
    min-height: 148px;
  }
}
@media screen and (min-width: 769px) and (max-width: 1024px) {
  footer .foot-2 a {
    flex: calc(100% / 9);
  }
}
@media screen and (min-width: 493px) and (max-width: 768px) {
  footer {
    width: 100%;
    height: 433px;
  }
  footer .foot-1 h3,
  footer .foot-1 h4,
  footer .foot-2 a,
  footer .foot-2 a:hover,
  footer .foot-3 a,
  footer .foot-3 h4 {
    font-size: 13px;
  }
  footer .flex {
    padding: 60px 52px;
    flex-direction: row;
  }
  footer .foot-1 {
    max-width: 215px;
    height: 248px;
    justify-content: space-between;
    align-items: flex-start;
  }
  footer .foot-2 {
    padding: 3px 0;
    height: 223px;
    max-width: 170px;
    justify-content: space-between;
    align-items: flex-start;
  }
  .foot-2 a {
    flex: calc(100% / 9) !important;
  }
  footer .foot-3 {
    width: 215px;
    padding: 3px 0px;
  }
}
@media screen and (max-width: 492px) {
  footer {
    padding: 50px 23px;
  }
  footer .foot-1 h3,
  footer .foot-1 h4,
  footer .foot-2 a,
  footer .foot-2 a:hover,
  footer .foot-3 a,
  footer .foot-3 h4 {
    font-size: 13px;
  }
  footer .flex {
    padding: 0;
    flex-direction: column;
  }
  footer .foot-1 {
    height: 208px;
    margin-bottom: 27px;
  }
  .foot-2 {
    max-width: 343px !important;
    margin-bottom: 27px;
    height: 148px;
  }
  .foot-2 a {
    margin-bottom: 8px !important;
    width: 50%;
    flex: calc(100% / 6) !important;
  }
  footer .foot-3 {
    max-width: 248px !important;
  }
}
