body.login {
  background-color: var(--main);
}

main#login {
  padding: 0;
  position: relative;
  min-height: inherit;
}
main#login .bg {
  position: relative;
  background-color: var(--main);
  width: 100%;
  min-height: inherit;
  overflow: hidden;
  padding: 35px;
}
main#login .bg #circle {
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  width: 380px;
  height: 380px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  border: 2px solid rgba(255, 255, 255, 0.1);
  box-sizing: border-box;
}
main#login .login-container {
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  padding: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 375px;
  height: 100%;
}
main#login .login-container .login-box {
  padding: 33px;
  width: 100%;
  background-color: var(--justWhite);
  border-radius: 20px;
}
main#login .login-container .login-box h1 {
  font-size: 18px;
  text-align: center;
  font-weight: 600;
  color: var(--header);
}
main#login .login-container .filterCtrl {
  margin: 19px 0;
  position: relative;
}
main#login .login-container .filterCtrl .col {
  display: flex;
  padding-left: 0px;
  padding-right: 0px;
}
main#login .login-container .filterCtrl .pass.col {
  justify-content: center;
}
main#login .login-container .filterCtrl .col a {
  font-size: 14px;
  text-decoration: none;
  font-weight: 400;
  color: var(--main);
}
main#login .login-container .filterCtrl label {
  display: block;
  margin-bottom: 5px;
  font-weight: 600;
  color: var(--header);
}
main#login .login-container .filterCtrl .form-check {
  margin-bottom: 0;
  min-height: auto;
}
.form-check-input:checked {
  background-color: var(--main);
  border-color: var(--main);
}
.form-check-input[type="checkbox"] {
  margin-top: 3px;
}
main#login .login-container .filterCtrl label.form-check-label {
  margin-bottom: 0px;
  display: block;
  font-size: 14px;
  font-weight: 400;
  color: var(--header);
}
main#login .login-container .filterCtrl input[type="email"],
main#login .login-container .filterCtrl input[type="password"] {
  background-color: var(--bg);
  width: 100%;
  height: 100%;
  color: var(--header);
  font-weight: 500;
  padding: 11px 10px;
  font-size: 14px;
  border-radius: 9px;
  padding-right: 30px;
  transition: 0.3s;
}
main#login .login-container .filterCtrl input:focus {
  box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
}
main#login .login-container .filterCtrl input[type="email"]::placeholder,
main#login .login-container .filterCtrl input[type="password"]::placeholder {
  color: var(--text);
}
main#login .login-container .filter-btn {
  width: 100%;
  height: 55px;
  border-radius: 16px;
  padding: 11px 0;
  text-align: center;
  font-weight: 600;
}
main#login .login-container .filter-btn.apply-filter {
  background: var(--btn-grad);
  color: var(--justWhite);
}
/* === Lawan Class Header === */
main#login .login-container .filter-btn {
  background: unset;
  max-width: unset;
  max-height: unset;
  margin-bottom: unset;
}
/* === responsive === */
@media screen and (max-width: 380px) {
  #root {
    width: 100%;
  }
}

@media screen and (min-width: 492px) {
  main#login .bg {
    overflow: visible;
  }
}

@media screen and (min-width: 768px) {
  main#login .bg #circle {
    width: 400px;
    height: 400px;
  }
}

@media screen and (min-width: 1020px) {
  #root {
    width: 100%;
  }
}
