.detail-lowongan-container {
  width: 100%;
  padding: 84px;
  padding-top: 100px;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  row-gap: 40px;
  font-family: "rubik", sans-serif;
}
.low-co {
  padding: 0 95px;
}
.head {
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  margin-bottom: 52px;
}
.head .title {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
}
.title h1 {
  width: 100%;
  margin: 0;
  font-weight: 600;
  font-size: 38px;
  color: var(--header);
}
#lamar-btn {
  padding: 17px 49px;
  color: var(--justWhite);
  background-color: var(--main);
  border-radius: 12px;
  font-size: 18px;
  font-weight: 500;
}
.bio {
  display: flex;
  flex-direction: row;
}
.bio h3 {
  margin: 0;
  font-size: 22px;
  font-weight: 500;
  color: var(--text);
  text-decoration: none;
}
.bio #company-name {
  display: flex;
  align-items: center;
  justify-content: center;
}
.bio #company-name::after {
  content: "oo";
  font-size: 7px;
  height: 8px;
  width: 8px;
  margin: 0 13px;
  background-color: var(--text);
  color: var(--text);
  opacity: 50%;
  border-radius: 50%;
}
.bio #company-name a {
  margin: 0;
  text-decoration: none;
  font-weight: 500;
  font-size: 22px;
  color: var(--main);
}
.bio #gap {
  font-size: 6.5px;
  margin: auto 0;
}
.dateCreated h3 {
  color: var(--text);
  margin: 0;
  font-size: 22px;
}
.detail-info {
  display: flex;
  flex-direction: column;
  row-gap: 52px;
}
.detail-info .describe h2,
.detail-info .condition h2,
.detail-info .more h2 {
  font-weight: 600;
  font-size: 20px;
  color: var(--header);
  margin-bottom: 17px;
}
.describe p {
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  text-align: justify;
  color: var(--text);
}
.condition li {
  list-style: disc;
  color: var(--text);
  line-height: 25px;
}
.moreInfo {
  display: flex;
  flex-direction: column;
  row-gap: 18px;
}
.list {
  display: flex;
  flex-direction: column;
  row-gap: 7px;
}
.list h3 {
  margin: 0;
  font-weight: 600;
  font-size: 18px;
  color: var(--header);
}
.list p {
  font-weight: 400;
  color: var(--text);
  font-size: 16px;
  margin: 0;
}
/* =========================================== Dashboard Lowongan Perusahaan ======================== */
.low-co .head .title .right {
  display: flex;
  width: 256px;
}
.low-co .head .title .right a {
  width: 119px;
  height: 50px;
  border-radius: 8px;
  padding: 11px 0 12px 0;
  text-align: center;
  text-decoration: none;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
}
.low-co .head .title .right a:first-of-type {
  background-color: var(--main);
  color: var(--justWhite);
  margin-right: 18px;
}
.low-co .head .title .right a:last-of-type {
  background-color: var(--justWhite);
  color: var(--btn-warning);
  border: 2px solid var(--btn-warning);
}
@media screen and (max-width: 768px) {
  .detail-lowongan-container {
    padding: 85px 52px;
    row-gap: 29px;
  }
  .title #lamar-btn {
    padding: 12px 36px;
    border-radius: 10px;
  }
  .head {
    row-gap: 8px;
  }
  .low-co {
    padding: 0 45px;
  }
  .title h1 {
    font-size: 27px;
  }
  .bio h3,
  .dateCreated h3 {
    font-size: 18px;
  }
}
@media screen and (max-width: 415px) {
  .detail-lowongan-container {
    padding: 85px 28px;
  }
  .low-co {
    padding: 0 18px;
  }
  .title #lamar-btn {
    display: none;
  }
  .lamar {
    display: block;
    width: 100%;
  }
  .lamar #lamar-btn {
    width: 100%;
    text-align: center;
  }
  .bio {
    align-items: center;
  }
  .bio #company-name a {
    max-width: fit-content;
  }
  .bio h3,
  .bio #company-name a,
  .dateCreated h3 {
    font-size: 16px;
  }
}
