.navbar {
  padding: 15px 0px;
  transition: 0.5s;
  background: var(--justWhite);
}
.navbar-light .navbar-toggler-icon {
  background-image: url("/public/img/icons/Menu-bar.svg");
}
.navbar-light .navbar-toggler {
  padding-right: 0;
  color: transparent;
  border-color: transparent;
}
.container,
.container-sm {
  max-width: 100%;
}
.navbar-expad {
  padding-top: 100px !important;
}
.navbar.scrolled {
  padding: 15px 0px;
  background: var(--justWhite);
}
.navbar .container {
  margin: 0 23px;
  padding: 0;
}
.navbar-brand img {
  height: 28px;
}
.navbar-nav a.masuk {
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  color: var(--header);
  padding: 8px 20px;
  text-decoration: none;
}
.navbar-nav a.akun-link img {
  width: 45px;
  border-radius: 50%;
}
.me-auto.navbar-nav {
  margin: 0 auto !important;
}
.me-auto.navbar-nav .nav-link {
  position: relative;
  color: var(--header);
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  padding-left: 22px;
  padding-right: 22px;
}
.me-auto.navbar-nav .nav-link.active {
  color: var(--main);
}
.akun-text-link {
  color: var(--header);
}
.akun-text-link.active {
  color: var(--main);
}
@media screen and (min-width: 768px) {
  .me-auto.navbar-nav .nav-link {
    text-align: left;
    padding-left: 20px;
    padding-right: 20px;
  }
  .me-auto.navbar-nav .nav-link.active::after {
    content: " ";
    top: 83%;
    left: 50%;
    border: 3px solid var(--main);
    position: absolute;
    border-radius: 50%;
  }
  .navbar {
    padding: 25px 0px;
    background: transparent;
  }
  .navbar .container {
    margin-right: 52px;
    margin-left: 52px;
    padding: 0;
  }
  .navbar-brand img {
    height: 35px;
  }
  .navbar-nav a.masuk {
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    border-radius: 8px;
    padding: 8px 20px;
    background-color: var(--main);
    color: var(--justWhite);
    text-decoration: none;
  }
  .me-auto.navbar-nav .nav-link {
    font-size: 14px;
  }
}
@media screen and (min-width: 992px) {
  .navbar {
    padding: 30px 0px;
  }
  .navbar-expad {
    padding-top: 100px !important;
  }
  .navbar.scrolled {
    padding: 15px 0px;
  }
  .navbar .container {
    margin: 0 84px;
    padding: 0;
  }
  .navbar-brand img {
    height: 39px;
  }
  .navbar-nav a.masuk {
    font-size: 16px;
    font-weight: 600;
    border-radius: 8px;
    padding: 8px 20px;
    background-color: transparent;
    border: 2px solid var(--main);
    color: var(--main);
    text-decoration: none;
  }
}
