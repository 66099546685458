.co {
    width: 100%;
}

.co #cover {
    height: auto;
    border-radius: 20px;
    width: 100%;
    background: none;
}

.co #pp {
    background-color: var(--bg);
    border: 5px solid var(--justWhite);
    /* transform: translate(95px, 237px); */
    border-radius: 14px;
    margin-top: -45px;
    margin-left: 95px;
    width: 90px;
    height: 90px;
}

.lamar {
    display: none;
}

@media screen and (max-width: 768px) {
    .co #cover {
        min-height: 258px;
        object-fit: cover;
    }

    .co #pp {
        margin-left: 45px;
        margin-top: -35px;
        width: 70px;
        height: 70px;
    }
}

@media screen and (max-width: 415px) {
    .co #cover {
        height: 217px;
        object-fit: cover;
    }

    .co #pp {
        margin-left: 18px;
    }
}
