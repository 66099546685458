main .dash-content {
  background-color: var(--bg);
  padding: 137px 50px 60px 50px;
}
a {
  text-decoration: none;
}
main .dash-content .content-lowongan {
  margin-bottom: 60px;
}
main .dash-content .content-lowongan div.between,
main .dash-content .content-alumni div.between {
  display: flex;
  justify-content: space-between;
  padding: 0 34px 35px 34px;
}
main .dash-content .content-lowongan h2,
main .dash-content .content-alumni h2 {
  font-weight: 600;
  font-size: 26px;
  line-height: 31px;
  color: var(--header);
}
main .dash-content .content-lowongan a,
main .dash-content .content-alumni a {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: var(--main);
}
main .dash-content .content-lowongan .contain-co {
  justify-content: space-evenly;
}
main .dash-content .content-alumni table {
  width: 100%;
  margin: 0 34px;
  border-radius: 20px;
  background-color: var(--justWhite);
}
main .dash-content .content-alumni table tr {
  border-bottom: 2px solid var(--bg);
}
main .dash-content .content-alumni table tr:last-of-type {
  border-bottom: unset;
}
main .dash-content .content-alumni table tr th {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: var(--header);
  padding: 32px;
}
main .dash-content .content-alumni table tr td,
main .dash-content .content-alumni table tr td a {
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  color: var(--text);
  padding: 20px 31px;
}
main .dash-content .content-alumni table tr td a {
  color: var(--main);
  padding: unset;
}
