.about-us {
  margin-bottom: 60px;
}
.about-us p {
  font-size: 13px;
  font-weight: 400;
  line-height: 25px;
  color: var(--text);
  margin-bottom: 15px;
}
.about-us a {
  font-size: 13px;
  font-weight: 500;
  line-height: 25px;
}
@media screen and (min-width: 768px) {
  .about-us p {
    font-size: 16px;
  }
  .about-us a {
    font-size: 16px;
  }
}