.news-padd {
  padding: 115px 85px 0 85px;
}
.news-padd .cover {
  height: 427px;
  border-radius: 20px;
}
.content {
  padding: 60px 95px;
}
.parag {
  margin-bottom: 60px;
}
.parag h1 {
  font-weight: 600;
  font-size: 38px;
  line-height: 45px;
  color: var(--header);
  margin-bottom: 15px;
}
.parag h3 {
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  color: var(--text);
  margin-bottom: 60px;
}
.parag p {
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: var(--text);
  margin-bottom: 15px;
}
.lampiran h2 {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 12px;
  color: var(--header);
}
.lampiran a i {
  content: url("../img/icons/document.svg");
  background-color: var(--justWhite);
  padding: 12px;
  background-repeat: no-repeat;
  background-size: cover;
  margin-right: 10px;
  border-radius: 8px;
}
.lampiran a,
.lampiran a h4 {
  text-decoration: none;
  color: var(--main);
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
}
.lampiran a {
  display: flex;
  align-items: center;
  width: 247px;
  padding: 10px;
  background-color: var(--bg);
  border-radius: 12px;
}
@media screen and (min-width: 493px) and (max-width: 768px) {
  .news-padd {
    padding: 115px 52px 0 52px;
  }
  .content {
    padding: 60px 45px;
  }
  .parag h1 {
    margin-bottom: 7px;
    font-size: 27px;
  }
  .parag h3 {
    font-size: 18px;
  }
}
