.acc-content.edit.dashboard {
  padding: 115px 180px 41px 180px;
}
.acc-content.edit .content-top h1 {
  font-weight: 600;
  font-size: 36px;
  line-height: 43px;
  color: var(--header);
  margin-bottom: 60px;
}
.acc-content.edit .content-top form .form-body {
  margin-bottom: 0;
}
.acc-content.edit .content-top form .form-top .lokasi {
  grid-column-end: 1 !important;
}
