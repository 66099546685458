.detail-company-container {
  padding: 60px 84px;
  display: flex;
  flex-direction: column;
  row-gap: 41px;
}
.com-co {
  display: flex;
  flex-direction: column;
  padding: 0 95px;
  row-gap: 38px;
}
.com-co .title.com {
  display: flex;
  justify-content: space-between;
}
.type h3 {
  color: var(--text);
  margin: 0;
  font-size: 22px;
}
.about-com h2 {
  font-weight: 600;
  font-size: 20px;
  color: var(--header);
  margin-bottom: 17px;
}
.about-com p {
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  text-align: justify;
  color: var(--text);
}
.card-slider {
  background-color: var(--bg);
  padding: 60px 96px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  row-gap: 35px;
}
.com-card {
  padding: 40px 31px;
  background-color: var(--justWhite);
  width: 440px;
  display: flex;
  border-radius: 20px;
  flex-direction: row;
  column-gap: 31px;
}
.com-card-icon {
  background-color: var(--main);
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.com-card-info {
  display: flex;
  flex-direction: column;
  row-gap: 9px;
}
.com-card-info h3 {
  font-size: 18px;
  margin: 0;
  font-weight: 600;
  color: var(--header);
}
.com-card-info p {
  font-size: 13px;
  margin: 0;
  font-weight: 400;
  color: var(--text);
} /* ======================================== Dashboard =============================== */
.com-co .title.com .left {
  display: flex;
  flex-direction: column;
  row-gap: 15px;
}
.com-co .title.com .right {
  width: 256px;
  display: flex;
}
.com-co .title.com .right a {
  width: 119px;
  height: 50px;
  border-radius: 8px;
  padding: 11px 0 12px 0;
  text-align: center;
  text-decoration: none;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
}
.com-co .title.com .right a:first-of-type {
  background-color: var(--main);
  color: var(--justWhite);
  margin-right: 18px;
}
.com-co .title.com .right a:last-of-type {
  background-color: var(--justWhite);
  color: var(--btn-warning);
  border: 2px solid var(--btn-warning);
}
@media screen and (max-width: 770px) {
  .detail-company-container {
    row-gap: 35px;
    padding: 60px 52px;
  }
  .com-card {
    max-width: 440px;
  }
  .card-slider {
    padding: 60px 55px;
  }
  .com-co {
    padding: 0 45px;
    row-gap: 45px;
  }
  .com-co .title.com {
    row-gap: 7px;
  }
  .type h3 {
    font-size: 17px;
  }
}
@media screen and (max-width: 415px) {
  .detail-company-container {
    padding: 60px 28px;
  }
  .com-co {
    padding: 0 18px;
  }
  .com-card {
    max-width: 248px;
    column-gap: 15px;
  }
}
