.err-content {
    position: absolute;
    width: 100%;
    top: 40.2%;
}

.err-content img {
    display: flex;
    margin: auto;
}

.err-content p {
    color: var(--text);
    font-weight: 600;
    font-size: 16px;
    text-align: center;
    margin-top: 15px;
}
