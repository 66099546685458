#home-header {
  padding: 23px;
  padding-top: 60px;
  min-height: 100vh;
  background-image: url("/public/img/qasuary/bg-phone.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.left-container {
  padding: 0;
  margin: 48px 0px;
  margin-bottom: 0;
}
.left-container h1 {
  font-size: 32px;
  font-weight: 600;
  text-align: center;
  color: var(--header);
}
.left-container span {
  color: var(--main);
}
.left-container p {
  margin: 22px auto;
  font-size: 14px;
  line-height: 25px;
  color: var(--text);
  text-align: center;
}
.header-search {
  padding: 8px 7px;
  padding-left: 19px;
  background: var(--justWhite);
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
}
.header-search .search-box {
  margin: auto 0;
  display: flex;
  width: 100%;
  padding-right: 15px;
}
.header-search input[type="text"] {
  margin-left: 7px;
  width: 100%;
  font-weight: 500;
  font-size: 12px;
  color: var(--header);
}
.header-search input[type="text"]::placeholder {
  font-weight: 500;
  font-size: 12px;
}
.header-search button {
  color: var(--justWhite);
  background: var(--btn-grad);
  text-align: center;
  width: 90px;
  height: 40px;
  font-weight: 500;
  font-size: 12px;
  border-radius: 8px;
}
.right-container {
  padding: 0;
  margin: 48px 0px;
  height: 273px;
  background-image: url("/public/img/qasuary/header-img.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

@media screen and (min-width: 768px) {
  #home-header {
    padding: 137px;
    background-image: url("/public/img/qasuary/bg-tablet.png");
  }
  .left-container h1 {
    font-size: 45px;
  }
  .left-container p {
    font-size: 16px;
  }
  .right-container {
    height: 390px;
  }
}

@media screen and (min-width: 992px) {
  #home-header {
    padding: 0px 84px;
    background-image: url("/public/img/qasuary/bg-dekstop.png");
    display: flex;
    align-items: center;
    min-height: 0vh;
    height: 100vh;
  }
  .left-container h1 {
    text-align: left;
  }
  .left-container p {
    text-align: left;
  }
  .right-container {
    margin: 0;
  }
  .header-search {
    max-width: 100%;
  }
}

@media screen and (min-width: 1024px) {
  .header-search {
    max-width: 85%;
  }
}