:root {
  --header: #2e3e5c;
  --text: #798ba4;
  --main: #4286f5;
  --bg: #f9fafc;
  --justWhite: #ffffff;
  --filter: invert(44%) sepia(39%) saturate(1346%) hue-rotate(185deg)
    brightness(103%) contrast(92%);
  --btn-grad: linear-gradient(
    321.48deg,
    #0554f2 -12.26%,
    #4286f5 38.13%,
    #7ccde3 91.14%
  );
  --btn-warning: #ff3b3b;
  font-size: 16px;
}
/* html {
  width: 375px;
} */
body {
  margin: auto;
  width: 100%;
  min-height: 100vh;
  font-family: "Rubik", sans-serif;
  background-color: var(--header);
}
#root {
  background-color: var(--justWhite);
  /* width: 375px !important; */
  margin: auto;
}
@media screen and (max-width: 380px) {
  #root {
    width: 100%;
  }
}

/* @media screen and (min-width: 768px) {
  #root {
    width: 768px;
  }
}

@media screen and (min-width: 1020px) {
  #root {
    width: 1020px !important;
  }
}

@media screen and (min-width: 1280px) {
  #root {
    width: 100% !important;
  }
} */
