.header-alter {
  height: 199px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url("/public/img/qasuary/footer.svg");
  background-size: cover;
  background-position: center;
  border-radius: 16px;
  margin-bottom: 60px;
}
/* search bar */
.header-alter.lowonganBanner{
  margin-bottom: 0px;
}
.header-alter .text-con {
  max-width: 211px;
}
.header-alter.lowonganBanner .text-con {
  max-width: 100% !important;
}
.header-alter .text-con h1 {
  font-size: 24px;
  font-weight: 600;
  color: var(--justWhite);
  text-align: center;
  margin-bottom: 14px;
}
.header-alter.lowonganBanner .text-con h1 {
  margin-bottom: 0px;
}
.header-alter .text-con p {
  font-size: 14px;
  font-weight: 400;
  color: var(--justWhite);
  text-align: center;
  margin-bottom: 0;
}

/* search bar */
.search-bar {
  position: relative;
}
.search-bar .header-search {
  max-width: 100%;
  /* margin: 0 16px; */
  border-radius: 8px;
  /* margin-top: -25px; */
  margin: 20px 0px;
  /* box-shadow: 0px 5px 15px 5px rgba(121, 139, 164, 0.09); */
}
.search-bar .header-search img {
  display: none;
}
.search-bar .header-search input[type="text"] {
  margin: 0 7px;
  margin-left: 0;
}
.search-bar .header-search input[type="text"]::placeholder {
  color: var(--text);
}
.search-bar .header-search button{
  height: 46px;
}
.filter-btn {
  background: var(--justWhite);
  border-radius: 6px;
  max-width: 40px;
  max-height: 40px;
  margin-bottom: 20px;
}
.filter-btn img {
  width: 20px;
  margin: 10px;
}
select.filterJurusan {
  background-color: white;
  width: 100%;
  padding: 22px 20px;
  border-radius: 8px;
  margin-bottom: 20px;
  font-size: 12px;
  font-weight: 500;
  color: var(--text);
}
.select-inside select.filterJurusan {
  padding: 0px !important;
  margin-bottom: 0px !important;
  font-size: 12px;
}
.select-area{
  position: relative;
}
.select-area img{
  position: absolute;
  top: 22px;
  right: 22px;
}
.select-inside{
  display: none;
  position: relative;
  width: 50%;
  border-left: 2px solid var(--bg);
  padding-left: 10px;
}
.select-inside img{
  position: absolute;
  top: 5px;
  right: 5px;
}
.search-bar .search-box {
  width: 80%;
}
@media screen and (max-width: 768px) {
  .search-box input[type="text"] {
    width: 90%;
  }
}
@media screen and (min-width: 768px) {
  .header-alter {
    height: 258px;
    border-radius: 20px;
  }
  .header-alter .text-con {
    max-width: 380px;
  }
  .header-alter .text-con h1 {
    font-size: 31px;
  }
  .search-bar .header-search {
    margin: 0 65px;
    margin-top: -25px;
    margin-bottom: 60px;
    box-shadow: 0px 5px 15px 5px rgba(121, 139, 164, 0.09);
  }
  .search-bar .header-search input[type="text"] {
    width: 40%;
}
  .search-bar {
    justify-content: space-around;
  }
  .search-box.lowongan {
    display: flex;
    width: 100%;
  }
  .search-box.lowongan .search {
    display: contents;
  }
  .search-bar .header-search img {
    display: block;
  }
  .search-bar .header-search input[type="text"] {
    margin: 0 7px;
    margin-left: 10px;
  }
  .select-area {
    display: none;
  }
  .select-inside {
    display: block;
  }
}
@media screen and (min-width: 1020px) {
  .header-alter {
    height: 282px;
  }
  .header-alter .text-con {
    max-width: 442px;
  }
  .header-alter .text-con h1 {
    font-size: 45px;
  }
  .header-alter .text-con p {
    font-size: 16px;
  }
  .search-bar .header-search {
    margin: 0 210px;
    margin-top: -25px;
    margin-bottom: 60px;
  }
}
