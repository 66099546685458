.account-hero {
  padding: 115px 85px 41px 85px;
}
.acc-content {
  padding: 0 180px;
}
.acc-content.edit .content-top form .form-berkas a.berkas,
.acc-content .body .foot-body .berkas a,
.acc-content .top {
  justify-content: space-between;
}
.acc-content .top .left h1 {
  font-weight: 600;
  font-size: 38px;
  line-height: 45px;
  color: var(--header);
}
.acc-content .top .left h2 {
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  color: var(--text);
}
.acc-content .top .right {
  width: 256px;
}
.acc-content .top .right a {
  width: 119px;
  height: 50px;
  border-radius: 8px;
  padding: 11px 0 12px 0;
  text-align: center;
  text-decoration: none;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
}
.acc-content .mobile-btn {
  display: none;
}
.acc-content .top .right a:first-of-type {
  background-color: var(--main);
  color: var(--justWhite);
  margin-right: 18px;
}
.acc-content .top .right a:last-of-type {
  background-color: var(--justWhite);
  color: var(--btn-warning);
  border: 2px solid var(--btn-warning);
}
.acc-content .body .top-body h2,
.acc-content .body .body-body .left h2,
.acc-content .body .body-body .right h2,
.acc-content .body .foot-body h2 {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: var(--header);
}
.acc-content .body .body-body .left h2,
.acc-content .body .body-body .right h2,
.acc-content .body .foot-body h2 {
  margin-bottom: 12px;
}
.acc-content .body .top-body h2 {
  margin: 40px 0 12px 0;
}
.acc-content .body .top-body p,
.acc-content .body .foot-body p {
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: var(--text);
}
.acc-content.edit .content-top form .form-berkas a.berkas,
.acc-content.edit .content-top form .form-berkas a.berkas div,
.acc-content .body .foot-body .berkas a div,
.acc-content .body .foot-body .berkas a,
.acc-content .body .body-body,
.acc-content .top .right,
.acc-content .top {
  display: flex;
}
.acc-content .body .top-body,
.acc-content .body .body-body {
  margin-bottom: 60px;
}
.acc-content .body .body-body .left,
.acc-content .body .body-body .right {
  width: 50%;
}
.acc-content .body .body-body .left h3,
.acc-content .body .body-body .left h4,
.acc-content .body .body-body .right h3,
.acc-content .body .body-body .right h4,
.acc-content .body .foot-body h3 {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
}
.acc-content .body .body-body .left h3,
.acc-content .body .body-body .right h3 {
  color: var(--header);
  margin-bottom: 5px;
}
.acc-content .body .body-body .left h4,
.acc-content .body .body-body .right h4 {
  color: var(--text);
  margin-bottom: 19px;
}
.acc-content .body .foot-body h3 {
  margin-bottom: 6px;
  color: var(--header);
}
.acc-content.edit .content-top form .form-berkas a.berkas,
.acc-content.edit .content-top form .form-berkas a.berkas h5,
.acc-content .body .foot-body h4,
.acc-content .body .foot-body .berkas a,
.acc-content .body .foot-body .berkas a h5 {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
}
.acc-content .body .foot-body h4 {
  color: var(--text);
}
.acc-content .body .foot-body .pengalaman,
.acc-content .body .foot-body .pendidikan {
  margin-bottom: 62px;
}
.acc-content.edit .content-top form .form-berkas a.berkas div i,
.acc-content .body .foot-body .berkas a div i {
  content: url("../img/icons/document.svg");
  background-color: var(--justWhite);
  padding: 12px;
  background-repeat: no-repeat;
  background-size: cover;
  margin-right: 10px;
  border-radius: 8px;
}
.acc-content.edit .content-top form .form-berkas a.berkas div,
.acc-content .body .foot-body .berkas a div {
  align-items: center;
}
.acc-content.edit .content-top form .form-berkas a.berkas,
.acc-content.edit .content-top form .form-berkas a.berkas h5,
.acc-content .body .foot-body .berkas a,
.acc-content .body .foot-body .berkas a h5 {
  text-decoration: none;
  color: var(--main);
  margin: 0;
}
.acc-content.edit .content-top form .form-berkas a.berkas,
.acc-content .body .foot-body .berkas a {
  width: 100%;
  padding: 10px;
  margin-bottom: 18px;
  background-color: var(--bg);
  border-radius: 12px;
}
.acc-content .body .foot-body .berkas a:last-of-type {
  margin-bottom: 46px;
}
.acc-content.edit .content-top form .form-berkas a.berkas i,
.acc-content .body .foot-body .berkas i {
  content: url("../img/icons/eye.svg");
  margin-right: 17px;
}
/* ======================================== EDIT ===================================== */
.acc-content.edit a {
  padding: 14px 26px;
  background-color: var(--bg);
  border-radius: 10px;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: var(--text);
  text-decoration: none;
}
.acc-content.edit .content-top {
  margin: 71px 0px 60px 0px;
}
.acc-content.edit .content-top form .form-top,
.acc-content.edit .content-top form .form-body,
.acc-content.edit .content-top form .form-bottom,
.acc-content.edit .content-top form .form-berkas {
  display: grid;
  grid-template-columns: 50% 50%;
  gap: 22px 40px;
  margin-bottom: 60px;
}
.acc-content.edit .content-top form div {
  height: max-content;
}
.acc-content.edit .content-top form .form-berkas a.berkas,
.acc-content.edit .content-top form .form-top div:last-of-type,
.acc-content.edit .content-top form .form-bottom .form-deskripsi {
  grid-column-start: 1;
  grid-column-end: 3;
}
.acc-content.edit .content-top form .form-top div#universitas,
.acc-content.edit .content-top form .form-top div#perusahaan {
  display: none;
}
.acc-content.edit .content-top form label {
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  width: 100%;
  margin-bottom: 14px;
  color: var(--header);
}
.acc-content.edit .content-top form input,
.acc-content.edit .content-top form textarea,
.acc-content.edit .content-top form select {
  background-color: var(--bg);
  width: 100%;
  height: 100%;
  color: var(--header);
  font-weight: 500;
  padding: 23px 26px;
  font-size: 14px;
  border-radius: 9px;
  padding-right: 30px;
  transition: 0.3s;
}
.acc-content.edit .content-top form div .select-inside {
  display: flex;
  align-items: center;
  width: unset;
  border: unset;
  padding: unset;
}
.acc-content.edit .content-top form div .select-inside img {
  top: unset;
  right: 23px;
}
.acc-content.edit .content-top form .form-body h2,
.acc-content.edit .content-top form .form-bottom h2,
.acc-content.edit .content-top form .form-berkas h2 {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: var(--header);
  margin-bottom: 23px;
}
.acc-content.edit .content-top form .form-body div,
.acc-content.edit .content-top form .form-bottom h2 {
  margin-bottom: 22px;
}
.acc-content.edit .content-bottom {
  margin-bottom: 60px;
}
.acc-content.edit .content-bottom a,
.acc-content.edit .content-top form .form-bottom a.hapus {
  margin-right: 18px;
  border-radius: 8px;
  width: 120px;
  border: 2px solid var(--main);
  background-color: var(--main);
  color: var(--justWhite);
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
}
.acc-content.edit .content-bottom a:last-of-type,
.acc-content.edit .content-top form .form-bottom a.hapus {
  background-color: var(--justWhite);
  color: var(--main);
  text-align: center;
}
.acc-content.edit .content-top form .form-bottom h2 {
  font-size: 22px;
  line-height: 26px;
}
.acc-content.edit .content-top form .form-bottom a:not(.hapus),
.acc-content.edit .content-top form .form-berkas a:not(.berkas) {
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: var(--main);
  padding: unset;
  background-color: unset;
  border-radius: unset;
  text-align: right;
}
.acc-content.edit .content-top form .form-bottom .date-range {
  display: grid;
  grid-template-columns: auto auto;
  gap: 55px;
}
.acc-content.edit .content-top form .form-berkas a.berkas i {
  content: url("../img/icons/trash.svg");
  margin-right: 17px;
}
.acc-content.edit .content-top form .form-berkas {
  row-gap: 0;
}
@media screen and (min-width: 493px) and (max-width: 768px) {
  .account-hero {
    padding: 115px 52px 41px 52px;
  }
  .acc-content {
    padding: 0 97px;
  }
}
@media screen and (max-width: 492px) {
  p {
    text-align: justify;
  }
  .account-hero {
    padding: 115px 28px 41px 28px;
  }
  .acc-content {
    padding: 0 46px;
  }
  .acc-content .body .body-body {
    display: block;
  }
  .acc-content .body .body-body .left {
    margin-bottom: 60px;
  }
  .acc-content .body .body-body .left,
  .acc-content .body .body-body .right {
    width: 100%;
  }
  .acc-content .top .right {
    display: none;
  }
  .acc-content .mobile-btn {
    display: flex;
    justify-content: space-between;
    margin-bottom: 85px;
  }
  .acc-content .mobile-btn a {
    width: 45%;
    height: 50px;
    border-radius: 8px;
    padding: 11px 0 12px 0;
    text-align: center;
    text-decoration: none;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
  }
  .acc-content .mobile-btn a:first-of-type {
    background-color: var(--main);
    color: var(--justWhite);
    margin-right: 18px;
  }
  .acc-content .mobile-btn a:last-of-type {
    background-color: var(--justWhite);
    color: var(--btn-warning);
    border: 2px solid var(--btn-warning);
  }
}
