main#JobList h2 {
  font-size: 20px;
  font-weight: 600;
  color: var(--header);
  margin-bottom: 40px;
}
main#JobList a#lihatSemua,
main#JobList a#lihatSemua:hover {
  color: var(--main);
  font-size: 14px;
  font-weight: 500;
}
.pad-sec {
  padding: 60px 0px;
  padding-bottom: 0px;
}
.con {
  padding: 60px 23px;
}
.con.gray {
  background: var(--bg);
}
.con.gray a{
  text-decoration: none;
}
.con.white {
  background: var(--justWhite);
}
.con .inline {
  display: flex;
  justify-content: space-between;
}

@media screen and (min-width: 768px) {
  main#JobList h2 {
    font-size: 32px;
  }
  main#JobList a#lihatSemua,
  main#JobList a#lihatSemua:hover {
    font-size: 16px;
  }
  .con {
    padding: 60px 52px;
  }
}
@media screen and (min-width: 992px) {
  .con {
    padding: 60px 84px;
  }
}